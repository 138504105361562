@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.pageHeader-wrapper{
  overflow: hidden;
  display: flex;
  width: 100%;
  .page-responsive-img{
    display: none;
  }
  .pageHeader-left_block{
    width: 50%;
    padding: 120px 64px 120px $mainPadding;
    .pageHeader-subtitle{
      height: 28px;
      span{
        color: #5056FD;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: #EFEFFF;
        padding: 2px 32px;
        border-radius: 12px;
        opacity: 0.8;
      }
    }
    .pageHeader-title{
      color: #0A0A0A;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
      margin-top: 24px;
    }
    .pageHeader-description{
      color: #5E5F78;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 24px;
    }
  }
  .pageHeader_right_block{
    width: 50%;
    padding: 120px $mainPadding 120px 120px;
    background: #0A0A0A;
    position: relative;
    .pageHeader{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: transform 0.2s;
      svg{
        animation: float 4s ease-in-out infinite;
      }
    }
    .pageHeaderEll{
      position: absolute;
      top: 36%;
      left: 44%;
      transform: translate(-50%,-50%);
      z-index: 99;
      transition: transform 0.2s;
      svg{
        animation: preloader 4s ease-in-out infinite;
      }
    }
  }

  @media screen and (max-width: 1050px){
    position: relative;
    .page-responsive-img{
      display: flex;
      position: absolute;
      top: 16px;
      right: 32px;
    }
    .pageHeader-left_block{
      width: 100%;
      padding: 64px $mainPadding;
      .pageHeader-title{
        color: #0A0A0A;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px;
      }
      .pageHeader-description{
        color: #5E5F78;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
    .pageHeader_right_block{
      display: none;
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes preloader {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-20px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}