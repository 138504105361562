@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";



.news-card{
  width: 100%;
  margin-top: 40px;
  .news-card-img{
    position: relative;
    aspect-ratio: 350 / 220;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 14px;
    }
  }
  .news-card-info{
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    .news-card-title-wrapper{
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-top: 12px;
      .news-card-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0;
        text-align: left;
        width: 96%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .news-card-link{
        cursor: pointer;
      }
    }
    .news-card-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: #667085;
      margin-top: 12px;
      p > span {
        font-size: 16px!important;
        font-weight: 400!important;
        line-height: 24px!important;
        letter-spacing: 0!important;
        text-align: left!important;
        overflow: hidden!important;
        text-overflow: ellipsis!important;
        display: -webkit-box!important;
        -webkit-line-clamp: 3!important;
        -webkit-box-orient: vertical!important;
        color: #667085!important;
        margin-top: 12px!important;
      }
    }
  }
  &:hover{
    .news-card-info{
      .news-card-title-wrapper{
        .news-card-title{
          color:$brandColor;
        }
        .news-card-link{
          svg{
            path{
              stroke:$brandColor
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px){
  .news-card{
    width: 100%;
    .news-card-img{
      aspect-ratio: 185 / 116;
    }
    .news-card-info{
      .news-date{
        font-size: 14px;
      }
      .news-card-title-wrapper{
        .news-card-title{
          font-size: 20px;
        }
      }
    }
  }
}