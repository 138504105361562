@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.distributorBlock-wrapper{
  position: relative;
  overflow: hidden;
  background: #5056FD;
  padding: 160px $mainPadding 110px $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .top-square{
    position: absolute;
    z-index: 99;
    top: 0;
    right: 26%;
  }
  .center-square{
    position: absolute;
    z-index: 99;
    top: 82px;
    right: 50%;
  }
  .distributor-header{
    width: 1120px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 52px;
    .distributor-header-left{
      width: 350px;
      .distributor-header-subtitle{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .distributor-header-title{
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        margin-top: 18px;
      }
    }
    .distributor-header-right{
      width: 510px;
      .distributor-header-description{
        padding-top: 40px;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
      .distributor-header-link{
        width: 192px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        border: 1px solid #FFF;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-top: 24px;
        cursor: pointer;
      }
    }
  }
  .distributorBlock-img{
    width: 1120px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1250px){
    .distributor-header{
      width: 1020px;
    }
    .distributorBlock-img{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1050px){
    padding: 64px $mainPadding 100px $mainPadding;
    .top-square{
      display: none;
    }
    .center-square{
      display: none;
    }
    .distributor-header{
      width: 100%;
      flex-direction: column;
      .distributor-header-left{
        width: 100%;
        .distributor-header-title{
          color: #FFF;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
      }
      .distributor-header-right{
        width: 100%;
        .distributor-header-description{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding-top: 20px;
        }
        .distributor-header-link{
          width: 100%;
        }
      }
    }
    .distributorBlock-img{
      width: 100%;
    }
  }
}