@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.ProgramsBlock-wrapper{
  padding: 124px $mainPadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ProgramsBlock-title{
    color: #5E5F78;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  .ProgramsBlock{
    width: 1120px;
    margin-top: 52px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    .programCard{
      width: calc(100% / 3 - 32px);
      height: 434px;
      position: relative;
      padding: 44px 32px 32px 32px;
      border-radius: 12px;
      background: #FAFAFE;
      .programCard-img{
        width: 80px;
        height: 30px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .programCard-title{
        color: #0A0A0A;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-top: 12px;
      }
      .programCard-options-block{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .programCard-options{
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          span{
            color: #5E5F78;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-left: 8px;
          }
        }
      }
      .programCard-link{
        cursor: pointer;
        border-radius: 40px;
        background: #F3F3FC;
        position: absolute;
        bottom: 32px;
        z-index: 99;
        width:calc(100% - 64px);
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        svg{
          path{
            stroke:#5E5F78;
          }
        }
      }

      &:hover{
        background: #EEF4FF;
        .programCard-title{
          color: #5056FD;
        }
        .programCard-options-block{
          .programCard-options{
            svg{
              path{
                fill: #5056FD;
              }
            }
            span{
              color: #5056FD;
            }
          }
        }
        .programCard-link{
          background: #5056FD;
          color: white;
          svg{
            path{
              stroke:white;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .ProgramsBlock{
      width: 1020px;
    }
  }
  @media screen  and (max-width: 1050px){
    padding: 54px $mainPadding;
    .ProgramsBlock-title{
      color: #5E5F78;
      text-align: left;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
    .ProgramsBlock{
      width: 100%;
      margin-top: unset;
      flex-direction: column;
      .programCard{
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}