@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.contacts-wrapper{
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .contact-info-wrapper{
    background: #5056FD;
    padding: 160px $mainPadding;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .contact-large-img{
      position: absolute;
      top: 0;
      right: 240px;
    }
    .contact-small-img{
      position: absolute;
      top: 30px;
      left: 80px;
    }
    .contact-info{
      width: 1120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .contact-email-block, .contact-address-block, .contact-phone-block{
        width: calc(100% / 3 - 22px);
        height: 368px;
        padding: 32px 32px 30px 32px;
        border-radius: 20px;
        border: 2px solid #FFF;
        .info-block-title{
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          margin-top: 46px;
        }
        .info-block-description{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 8px;
        }
        .info-block{
          margin-top: 24px;
          span{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            border-bottom: 1px solid white;
          }
        }

        &:hover{
          background-color:#676CFD;
          border: unset;
        }
      }
    }
  }
  .contact-input-wrapper{
    width: 916px;
    padding: 144px 0;
    .input-wrapper-subtitle{
      border-radius: 12px;
      opacity: 0.8;
      background: #EFEFFF;
      height: 28px;
      width: 252px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5056FD;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .input-wrapper-title{
      color: #0A0A0A;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: -0.4px;
      margin-top: 20px;
    }
    .input-wrapper-description{
      color: #5E5F78;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    .contacts-inputs{
      margin-top: 52px;
      .fullName-inputs{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .custom-input-group{
          width: 48%;
        }
      }
      .inputs{
        .custom-input-group{
          margin-top: 16px;
        }
      }
      .sent-email{
        border-radius: 40px;
        background: #5056FD;
        width: 100%;
        height: 56px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .contact-info-wrapper{
      .contact-info{
        width: 1020px;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .contact-info-wrapper{
      padding: 64px $mainPadding;
      overflow: hidden;
      .contact-large-img{
        top: unset;
        bottom: 0;
        left: unset;
        right: -40px;
        rotate: 180deg;
      }
      .contact-small-img{
        top: -40px;
        left: -40px;
      }
      .contact-info{
        width: 100%;
        flex-direction: column;
        z-index: 99;
        position: relative;
        .contact-email-block, .contact-address-block, .contact-phone-block{
          width: 100%;
          margin-top: 32px;
        }
      }
    }
    .contact-input-wrapper{
      overflow: hidden;
      width: 96%;
      padding: 72px $mainPadding;
      .input-wrapper-title{
        color: #0A0A0A;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.26px;
      }
      .input-wrapper-description{
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .contacts-inputs{
        .fullName-inputs{
          flex-direction: column;
          .custom-input-group{
            width: 100%;
            margin-top: 16px;
          }
        }
      }
    }
  }
}