@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.mainBlock-wrapper{
  display: flex;
  .mainBlock-white{
    width: 50%;
    padding: 120px 64px 120px $mainPadding;
    .mainBlock-subtitle{
      border-radius: 12px;
      opacity: 0.8;
      background: #EFEFFF;
      height: 28px;
      width: 252px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5056FD;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .mainBlock-title{
      color: #0A0A0A;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
      margin-top: 24px;
    }
    .mainBlock-description{
      color: #5E5F78;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-top: 24px;
    }
    .responsive-img{
      display: none;
    }
    .mainBlock-link{
      cursor: pointer;
      padding: 20px 40px;
      border-radius: 40px;
      background: #5056FD;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      width: 250px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      svg{
        margin-left: 6px;
      }
    }
  }
  .mainBlock-black{
    width: 50%;
    padding: 120px $mainPadding 120px 120px;
    background: #0A0A0A;
    position: relative;
    .mainBlock-oval{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: transform 0.2s;
      svg{
        animation: float 4s ease-in-out infinite;
      }
    }
    .mainBlock-cub{
      position: absolute;
      top: 36%;
      left: 44%;
      transform: translate(-50%,-50%);
      z-index: 99;
      transition: transform 0.2s;
      svg{
        animation: preloader 4s ease-in-out infinite;
      }
    }
  }

  @media screen and (max-width: 1050px){
    overflow: hidden;
    .mainBlock-black{
      display: none;
    }
    .mainBlock-white{
      width: 100%;
      padding: 64px $mainPadding;
      position: relative;
      .mainBlock-subtitle{
        display: none;
      }
      .mainBlock-title{
        width: 90%;
        color: #0A0A0A;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px; /* 127.778% */
      }
      .mainBlock-description{
        color: #5E5F78;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
      .mainBlock-link{
        width: 100%;
      }
      .responsive-img{
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes preloader {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-20px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}