@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.projectBlock-wrapper{
  padding: 120px $mainPadding 100px $mainPadding;
  overflow: hidden;
  .projectBlock-header{
    width: 1120px;
    display: flex;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,0);
    .projectBlock-left{
      width: 50%;
      .projectBlock-title{
        width: 468px;
        color: #0A0A0A;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
      }
      .projects-block-title{
        width: 558px;
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 106px;
      }
      .projects-block-btn{
        width: 192px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border-radius: 40px;
        background: $brandColor;
        margin-top: 32px;
      }
    }
    .projectBlock-right{
      width: 50%;
      .projectBlock-description{
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .projectBlock-info{
        width:338px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .projectBlock-line{
          height: 76px;
          width: 1px;
          background: #BABBD0;
          margin: 0 16px 0 12px;
        }
        .projectBlock-films, .projectBlock-subscribers{
          margin-left: 4px;
          span{
            color: #0A0A0A;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 52px;
          }
          p{
            color: #5E5F78;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 6px;
          }
        }
      }
    }
  }
  .projects-block{
    margin-top: 104px;
    .projects {
      position: relative;
      margin-top: 40px;

      .slick-slider {
        .slick-list {
          .slick-track {
            display: flex;
            margin-left: auto;
            padding-top: 5px;
          }
        }
      }

      .projects-item  {
        width: 100%;
        height: 100%;
        padding-top: 39%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          //height: 100%;
          //margin: auto;
          //transition: all 0.3s;
          padding: 0 10px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
      .prev-btn , .next-btn{
        top: unset;
        transform: unset;
        margin-top: 40px;
        border: 1px solid #EAECF0;
        box-shadow: unset;
      }
      .next-btn{
        right: unset;
        left: 60px;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .projectBlock-header{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1050px){
    padding: 64px $mainPadding;
    .projectBlock-header{
      width: 100%;
      flex-direction: column;
      .projectBlock-left{
        width: 100%;
        .projectBlock-title{
          width: 100%;
          color: #0A0A0A;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
      }
      .projectBlock-right{
        width: 100%;
        margin-top: 20px;
        .projectBlock-description{
          color: #5E5F78;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .projectBlock-info{
          width: 100%;
        }
      }
    }
    .projects-block{
      margin-top: 80px;
      .projects-block-title{
        width: 100%;
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .projects{
        .prev-btn , .next-btn{
          display: none;
        }
      }
    }
  }
}