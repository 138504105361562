@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.news-wrapper{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .news-block{
    width: 1120px;
    padding: 60px 0 200px 0;
    .news-subtitle{
      height: 28px;
      span{
        color: #5056FD;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        background: #EFEFFF;
        padding: 2px 32px;
        border-radius: 12px;
        opacity: 0.8;
      }
    }
    .news-title{
      color: #0A0A0A;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: -0.4px;
      margin-top: 20px;
    }
    .news-description{
      width: 70%;
      color: #5E5F78;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    .news-slider{
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      .news-card{
        width: calc(100% / 3 - 24px);
      }
    }
  }

  .pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    &.hide-prev{
      .ant-pagination-prev{
        display: none;
      }
    }
    &.hide-prev{
      .ant-pagination-next{
        display: none;
      }
    }
    .prev_btn{
      height: 40px;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
      }
      svg{
        margin-right: 12px;
      }
    }
    .next_btn{
      height: 40px;
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
      svg{
        margin-left: 12px;
      }
    }
    .ant-pagination-item{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      a{
        color: #475467;
      }
    }
    .ant-pagination-item-active{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background:#F9FAFB;
      a{
        color: #1D2939;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .newsBlock{
      width: 1020px;
    }
    .news-block{
      width: 1020px;
    }
  }

  @media screen and (max-width: 1050px){
    .news-block{
      width: 100%;
      padding: 64px $mainPadding 140px $mainPadding;
      .news-title{
        color: #0A0A0A;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -0.26px;
      }
      .news-description{
        width: 100%;
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .news-slider{
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        .news-card{
          width: calc(100% / 2 - 16px);
        }
      }
    }
  }
}