@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.right-menu-wrapper {
  position: fixed;
  top: 90px;
  right: 0;
  z-index: 9901;
  width: 100%;
  height: 96vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border-top: 1px solid #EBEBEB;

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }

  .right-menu-content {
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    z-index: 9002;
    height: 100%;
    background-color: white;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 0;
    }

    .ant-dropdown-trigger {
      margin-top: 10px;
      color: $brandColor;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .arrow-icon {
        width: 14px;
        height: 14px;
        transform: rotate(90deg);
        margin: 0 30px 0 8px;
      }
    }

    .ant-menu-root > .ant-menu-item {
      border-bottom: 2px solid $greyBorderColor;
      position: relative;

      .badge {
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }

    .ant-menu-root > .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 0 !important;
        border-bottom: 2px solid $greyBorderColor;

        &:hover {
          //color: $greyColor;
        }
      }

      .ant-menu {
        .ant-menu-submenu-title {
          border-bottom: 0;
        }
      }
    }

    .ant-menu-item:active, .ant-menu-submenu-title:active {
      background-color: white;
    }

    .ant-menu {
      width: 100%;
      margin-top: 10px;

      .ant-menu-item {
        background-color: transparent;
        color: $lightGreyColor;
        border-right: 0;
        padding-left: 0 !important;
        display: flex;
        justify-content: space-between;

        a {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .category-item-img {
          width: 25px;
          height: 25px;
          margin-right: 5px;
        }

        a {
          width: 100%;
          height: 100%;
        }

        &::after {
          border-right: 0 !important;
        }
      }
    }

    .ant-menu-inline {
      border-right: 0;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: 45px;

        &:hover {
          color: $greyColor;
        }
      }
    }

    .mobile-nav{
      display: none;
    }
    .mobile-nav, .desktop-nav {
      width: 100%;
      flex-direction: column;
      text-decoration: none;
      .active{
        .nav-item{
          color:$brandColor;
        }
      }

      .nav-item {
        text-decoration: none;
        color: #231F20;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 14px 0;

        &:hover {
          color: $brandColor;
        }
      }
      .header-services{
        li{
          .active{
            color:$brandColor;
          }
        }
      }

      .header-services{
        //list-style: revert;
        margin-left: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #475467;
        li{
          margin: 14px 0;
          cursor: pointer;
          &:hover {
            color: $brandColor;
          }
        }
      }
      .telematic-block{
        width: 96%;
        height: 56px;
        border: 1px solid #475467;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #101828;
        margin-top: 28px;
      }
      .language-items{
        width: 88%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 94px;

        .language-dropdown-item{
          width:calc(90% / 3);
          display: flex;
          align-items: center;
          justify-content: center;
          .selected-language{
            border: 1px solid #EFF2F4;
            border-radius: 4px;
            padding: 12px 30px;
            cursor: pointer;
            color: black;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            .language_icon{
             img{
               margin: unset;
             }
            }
          }
          .active-language{
            background-color: #EFF2F4;
          }
        }
      }

    }

    @media all and (max-width: 1050px) {
      .mobile-nav {
        display: flex;
      }
    }
  }

  .mobile-menu-overlay {
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
    opacity: 0.55;
  }


  & {
    .hide {
      display: none;
    }
  }


}

//.ant-dropdown {
//  background:white;
//}