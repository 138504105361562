@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.about-wrapper{
  overflow: hidden;
  padding-bottom: 80px;
  .about-blocks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .about-blogs-wrapper{
      .about-block{
        display: flex;
        &:nth-child(2n){
          flex-direction: row-reverse;
          .about-info-block{
            padding: 116px  128px 116px $mainPadding;
          }
        }
        .about-count-block{
          width: 50%;
          background: #5056FD;
          display: flex;
          align-items: center;
          justify-content: center;
          .about-count{
            width: 300px;
            height: 300px;
            border-radius: 300px;
            border: 3px solid #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p{
              color: #FFF;
              text-align: center;
              font-size: 52px;
              font-style: normal;
              font-weight: 700;
              line-height: 64px;
            }
            span{
              color: #FFF;
              text-align: center;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
            }
          }
        }
        .about-info-block{
          width: 50%;
          padding: 116px  $mainPadding 116px 128px;
          color: #5E5F78;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding-bottom: unset;
    .about-blocks{
      .about-blogs-wrapper{
        padding: 28px $mainPadding;
        border-bottom: 1px solid #DADADA;
        .about-block{
          flex-direction: column;
          &:nth-child(2n){
            flex-direction: column;
            .about-info-block{
              padding: 32px 0;
            }
          }
          .about-count-block{
            width: 100%;
            border-radius: 12px;
            background: #5056FD;
            padding: 20px;
          }
          .about-info-block{
            width: 100%;
            padding: 32px 0;
            color: #5E5F78;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
}