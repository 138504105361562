@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.footer-wrapper{
  background: #0A0A0A;
  padding: 140px $mainPadding 0 $mainPadding;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .footer-top-block{
    width: 1120px;
    padding-bottom: 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .footer-pdf-download{
      position: absolute;
      bottom: 26px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-decoration: underline;
      color: white;
      cursor: pointer;
    }
    .footer-left-block{
      .footer-contact{
        margin-top: 40px;
        display: flex;
        align-items: center;
        color: #FFF;
        //font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        svg{
          margin-right: 6px;
        }
      }
      .footer-email{
        margin-top: 20px;
        display: flex;
        align-items: center;
        color: #FFF;
        //font-family: NotoArmenian;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        svg{
          margin-right: 6px;
        }
      }
    }
    .footer-right-blocks{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .footer-info-block{
        margin-left: 150px;
        display: flex;
        flex-direction: column;
        .footer-info-title{
          color: #BABBD0;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .active{
          .nav-item{
            color:$brandColor;
          }
          .nav-contact{
            color:$brandColor;
            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
        a{
          list-style-type: none;
          text-decoration: none !important;
          .nav-item{
            margin-top: 16px;
            color: #FFF;
            //font-family: NotoArmenian;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            &:hover{
              color:$brandColor;
            }
          }
        }
        .footer-info{
          width: 270px;
          margin-top: 16px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          &:hover{
            color:$brandColor;
          }
        }
      }
    }
  }
  .footer-bottom-block{
    width: 1120px;
    padding: 25px 0 40px 0;
    border-top: 1px solid #2B2C2E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      color: #BABBD0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .developed-block{
      color: #BABBD0;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      a{
        color: #667085;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 24px;
        margin-left: 6px;
        text-align: right;
        text-decoration: underline!important;
      }
    }
  }

  @media screen and (max-width: 1250px){
    .footer-top-block{
      width: 1020px;
    }
    .footer-bottom-block{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1050px){
    padding: 50px $mainPadding 0 $mainPadding;
    .footer-top-block{
      width: 100%;
      flex-direction: column;
      align-items: unset;
      padding-bottom: 20px;
      .footer-right-blocks{
        margin-top: 68px;
        align-items: unset;
        flex-direction: column;
        .footer-info-block{
          margin-left: unset;
          margin-bottom: 40px;
        }
      }
    }
    .footer-bottom-block{
      width: 100%;
    }
  }
}