@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";

.newsBlock{
  width: 1120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0 80px 0;
  .newsBlock-wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    .newsBlock-card{
      width: calc(100% / 2 - 16px);
      padding: 48px 40px;
      border-radius: 20px;
      background: #5056FD;
      .newsBlock-title{
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        margin-top: 72px;
      }
      .newsBlock-description{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
      }
      .newsBlock-pdf-wrapper{
        display: flex;
        flex-direction: column;
        .newsBlock-pdf{
          cursor: pointer;
          margin-top: 32px;
          width: 180px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          border-bottom: 1px solid white;
          svg{
            margin-right: 6px;
          }
        }
      }
      .newsBlock-link-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 52px;
        .newsBlock-link{
          width:50%;
          span{
            width: 100px;
            display: flex;
            align-items: center;
            margin-top: 20px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            border-bottom: 1px solid white;
            svg{
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1250px){
      width: 1020px;
  }
  @media screen and (max-width: 1050px){
    width: 100%;
    padding: 0 $mainPadding 0 $mainPadding;
    .newsBlock-wrapper{
      width: 100%;
      flex-direction: column;
      flex-wrap: unset;
      gap: unset;
      .newsBlock-card{
        width: 100%;
        margin-top: 32px;
        padding: 42px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .newsBlock-title{
          color: #FFF;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          text-align: center;
          margin-top: 32px;
        }
        .newsBlock-description{
          color: #FFF;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}