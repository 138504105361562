@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.partnerBlock-wrapper{
  overflow: hidden;
  padding: 200px 0;
  .partnerBlock-header{
    padding: 0 $mainPadding;
    position: relative;
    .partnerBlock-icon{
      position: absolute;
      left: 0;
      bottom: 90px;
    }
    .partnerBlock-subtitle{
      width: 130px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      opacity: 0.8;
      background: #EFEFFF;
      color: #5056FD;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .partnerBlock-title{
      color: #0A0A0A;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
      margin-top: 20px;
    }
    .partnerBlock-description{
      width: 640px;
      color: #5E5F78;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 20px;
    }
  }
  .partnerBlock{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-36%,10px);
    .slick-slider {
      .slick-list {
        .slick-track {
          display: flex;
          margin-left: auto;
          padding-top: 5px;
        }
      }
    }
    .partners-item{
      padding: 0 $mainPadding 0 0;
      margin-left: $mainPadding;
      margin-top: 52px;
      position: relative;
      .partners-img-wrapper{
        width: 1120px;
        padding-right: $mainPadding;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .partners-imgBg{
          border-radius: 20px;
          background: #0A0A0A;
          width: 80%;
          height: 598px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .partners-info{
        overflow: hidden;
        width: 544px;
        position: absolute;
        z-index: 99;
        left: 0;
        top: 56px;
        bottom: 56px;
        border-radius: 20px;
        background: #5056FD;
        padding: 38px 32px;
        svg{
          width: 80px;
          height: 80px;
        }
        .partners-title{
          color: #FFF;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
          margin-top: 40px;
        }
        .partners-description{
          height: 150px;
          overflow: hidden;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          overflow: hidden!important;
          text-overflow: ellipsis!important;
          display: -webkit-box!important;
          -webkit-line-clamp: 6!important;
          -webkit-box-orient: vertical!important;
        }
        .partners-more{
          border-radius: 40px;
          background: #676CFD;
          width: 192px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-top: 32px;
          cursor: pointer;
        }
      }
    }
    .prev-btn , .next-btn{
      top: unset;
      transform: unset;
      margin-top: 40px;
      border: 1px solid #EAECF0;
      box-shadow: unset;
      margin-left: $mainPadding;
    }
    .next-btn{
      right: unset;
      left: 60px;
    }
  }

  @media screen and (max-width: 1400px){
    .partnerBlock{
      transform: translate(-44%,10px);
    }
  }

   @media screen and (max-width: 1250px){
    .partnerBlock{
      transform: translate(-50%,10px);
      .partners-item{
        .partners-img-wrapper{
          width: 1020px;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    padding: 52px 0;
    .partnerBlock-header{
      .partnerBlock-icon{
        width: 98px;
        height: 78px;
        left: unset;
        right: 16px;
        bottom: unset;
        top: -30px;
        svg{
          width: 100%;
          height: 100%;
        }
      }
      .partnerBlock-title{
        color: #0A0A0A;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }
      .partnerBlock-description{
        width: 100%;
        color: #5E5F78;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .partnerBlock{
      position: unset;
      transform: unset;
      .partners-item{
        padding: $mainPadding;
        margin-left: unset;
        .partners-img-wrapper{
          display: none;
        }
        .partners-info{
          position: unset;
          width: 100%;
          .partners-more{
            width: 100%;
          }
        }
      }
      .prev-btn , .next-btn{
        display: none;
      }
    }
  }
}