@import "../../../../assets/styles/fonts";
@import "../../../../assets/styles/mainVariables";

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.newsModal {
  width: 620px !important;
  z-index: 1000;

  .ant-modal-content {
    border-radius: 12px;
    position: relative;

    .ant-modal-close {
      width: 40px;
      height: 40px;
      position: absolute;
      right: -40px;
      top: -18px;
      cursor: pointer;

      .ant-modal-close-x {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.5s;

        svg {
          path {
            stroke: white;
          }
        }
      }

      &:hover {
        .ant-modal-close-x {
          //background-color: rgba(255, 255, 255, 0.4) !important;
          cursor: pointer;

          svg {
            path {
              stroke: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }
    }

    @media screen and (max-width: 1050px) {
      .ant-modal-close {
        right: 3px;
        left: unset;
        top: -6px;

        .ant-modal-close-x {
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: color 0.5s;

          svg {
            path {
              stroke: black !important;
            }
          }
        }
      }
    }
  }
}

.news-modal-wrapper {
  width: 100%;
  border-radius: 12px;

  .news-video-wrapper {
    width: 100%;
    aspect-ratio: 570 / 360;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    .video-cover {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #16C67B;
      z-index: 2;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s;

      &.hide-cover {
        visibility: hidden;
        opacity: 0;
      }

      .cover-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .play-btn {
        position: absolute;
        width: 72px;
        height: 72px;
        top: calc(50% - 36px);
        left: calc(50% - 36px);
        z-index: 4;
        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
          transition: all 0.7s;

          path {
            transition: all 0.3s;
          }

          rect {
            transition: all 0.7s;
          }
        }

        &:hover {
          svg {
            transform: scale(1.07, 1.07);

            path {
              fill: $brandColor;
            }

            rect {
              fill: #EFEFFF;
            }
          }
        }
      }
    }

    .video-wrapper{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      .video-react {
        padding-top: unset!important;
        height: 100%;
        font-family: inherit;
        video{
          object-fit: cover;
        }
      }
    }


    .video-react-big-play-button {
      display: none;
    }
  }

  .slick-slider {
    width: 100%;
    //height: 286px;
    .slick-list {
      width: 100%;
      height: 100%;

      .news-block-img {
        aspect-ratio: 570 / 360;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        img{
          border-radius: 12px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .news-block-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;

    .news-date {
      color: #1A5AFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }

    .news-modal-link {
      color: #1A5AFF;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 6px;
    }

    .news-block-title {
      color: #231F20;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      margin-top: 6px;
    }

    .news-block-description {
      color: #696773;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 8px;

      p {
        color: #696773;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        margin-top: 8px;

        span {
          color: #696773 !important;
          text-align: center !important;
          font-size: 20px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 30px !important;
          margin-top: 8px !important;
        }
      }
    }

    .news-modal-pdf {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      text-decoration: underline;
      color: #696773;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1050px) {
  .newsModal {
    width: 100%;
  }
  .news-modal-wrapper {

    .video-cover {
      .play-btn {
        position: absolute;
        width: 56px;
        height: 56px;
        top: calc(50% - 28px);
        left: calc(50% - 28px);
      }
    }
  }
}