@import "../../assets/styles/_mainVariables.scss";
@import "../../assets/styles/fonts";

.header-wrapper{
  display: flex;
  .fix-position{
    position: fixed;
    z-index: 999;
  }
  .header-white-block{
    width: 50%;
    padding: 24px 0 24px 80px;
    background-color: white;
    .header_burger{
      display: none;
    }
    a{
      svg{
        width: 36px;
        height: 62px;
      }
    }
  }
  .header-black-block{
    width: 50%;
    padding: 42px $mainPadding 42px 44px;
    background-color: #0A0A0A;
    .link-menu{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav-language{
        display: flex;
        .ant-dropdown-link{
          .selected-language{
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-left: 24px;
            display: flex;
            .language_icon{
              width: 100px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin: unset;
              }
            }
          }
        }
      }
      .active{
        .nav-item{
          color:$brandColor;
        }
        .nav-contact{
          color:$brandColor;
          svg{
            path{
              stroke:$brandColor;
            }
          }
        }
      }
      a{
        list-style-type: none;
        text-decoration: none !important;
        .nav-item{
          list-style-type: none;
          text-decoration: none !important;
          color: #FFF;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-right: 24px;
          @media screen and (min-width: 1500px){
            font-size: 16px;
          }
          &:hover{
            color:$brandColor;
          }
        }
        .nav-contact{
          list-style-type: none;
          text-decoration: none !important;
          color: #FFF;
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin: 0 8px 0 24px;
          display: flex;
          align-items: center;
          @media screen and (min-width: 1500px){
            font-size: 16px;
          }
          &:hover{
            color:$brandColor;

            svg{
              path{
                stroke:$brandColor;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    border-bottom: 1px solid #DADADA;
    .header-black-block{
      display: none;
    }
    .header-white-block{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px $mainPadding;
      .header_burger{
        display: flex;
      }
    }
  }
}