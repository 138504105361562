@import "../../assets/styles/mainVariables";
@import "../../assets/styles/fonts";


.service-wrapper{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .service-blocks{
    width: 1120px;
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .service-blocks-title{
      color: #0A0A0A;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
    }
    .service-blocks-description{
      width: 80%;
      color: #5E5F78;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      margin-top: 20px;
    }
    .service-first-block{
      border-radius: 20px;
      background: #111;
      padding: 84px 72px;
      display: flex;
      align-items: center;
      margin-top: 72px;
      justify-content: space-between;
      .first-block-info{
        width: 50%;
        .first-block-title{
          color: #FFF;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
        .first-block-description{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .first-block-more{
          width: 250px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          background: #5056FD;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          margin-top: 32px;
          svg{
            margin-left: 6px;
          }
        }
      }
    }
    .service-distribution-block{
      padding: 120px 0;
      .service-distribution-info{
        width: 80%;
        color: #5E5F78;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }
      .service-distribution-wrapper{
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 52px;
        .service-distribution-item{
          width: calc(100% / 5 - 52px);
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .service-second-block{
      border-radius: 20px;
      background: #5056FD;
      padding: 114px 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
      .second-block-info{
        width: 50%;
        .second-block-title{
          color: #FFF;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
        .second-block-description{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 20px;
        }
        .second-block-more{
          width: 250px;
          height: 56px;
          border-radius: 40px;
          background: #676CFD;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
          svg{
            margin-left: 6px;
          }
        }
        .second-block-download{
          width: 180px;
          margin-top: 40px;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          border-bottom: 1px solid white;
          svg{
            margin-right: 8px;
          }
        }
      }
      .second-block-images{
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        .large-ellipse{
          margin: 0 200px 100px 0;
        }
        .small-ellipse{
          margin-right: 160px;
        }
      }
    }
  }

  @media screen and (max-width: 1250px){
    .service-blocks{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1050px){
    .service-blocks{
      width: 100%;
      padding: 24px 0 0 0;
      border-top: 1px solid #DADADA;
      align-items: unset;
      .service-blocks-title{
        padding: 0 $mainPadding;
        color: #0A0A0A;
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }
      .service-blocks-description{
        padding: 0 $mainPadding;
        color: #5E5F78;
        text-align: left;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .service-first-block{
        border-radius: unset;
        padding: 64px $mainPadding;
        position: relative;
        overflow: hidden;
        .first-block-info{
          width: 100%;
          .first-block-title{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
          }
          .first-block-description{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          .first-block-more{
            width: 100%;
            margin-top: 20px;
          }
        }
        .first-block-images{
          position: absolute;
          top: -40px;
          right: 0;
          display: flex;
          .small-cub-wrapper{
            margin-top: 60px;
          }
          .large-cub-wrapper{
            display: none;
          }
        }
      }
      .service-distribution-block{
        padding: 64px $mainPadding;
        .service-distribution-info{
          width: 100%;
          color: #5E5F78;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
        .service-distribution-wrapper{
          gap: 16px;
          .service-distribution-item{
            width: calc(100% / 2 - 8px);
            border-radius: 4px;
            border: 0.5px solid #DADADA;
          }
        }
      }
      .service-second-block{
        border-radius: unset;
        padding: 64px $mainPadding;
        position: relative;
        overflow: hidden;
        .second-block-info{
          width: 100%;
          z-index: 99;
          .second-block-title{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
          }
          .second-block-description{
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
          .second-block-more{
            width: 100%;
          }
        }
        .second-block-images{
          display: unset;
          position: absolute;
          top: -40px;
          right: 40px;
          .small-ellipse{
            display: none;
          }
        }
      }
    }
  }
}