@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.serviceBlock-wrapper{
  padding: 144px $mainPadding 230px $mainPadding;
  background: #0A0A0A;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .serviceBlock-responsiveImg{
    //display: none;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .serviceBlock-header{
    width: 748px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .serviceBlock-header-subtitle{
      color: #BABBD0;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      width: 150px;
      border-radius: 12px;
      opacity: 0.8;
      background: #1C1C1C;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .serviceBlock-header-title{
      color: #FFF;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 52px;
      margin-top: 32px;
    }
  }
  .serviceBlocks{
    width: 1120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 90px;
    .serviceFirstCard{
      width: 100%;
      border-radius: 20px;
      background: #111;
      padding: 72px;
      display: flex;
      align-items: center;
      position: relative;
      .serviceFirstCard-topImg{
        position: absolute;
        top: 0;
        right: 40%;
        z-index: 99;
      }
      .serviceFirstCard-left{
        width: 50%;
        .serviceFirstCard-title{
          color: #FFF;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px;
        }
        .serviceFirstCard-description{
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-top: 20px;
        }
        .serviceFirstCard-more{
          margin-top: 32px;
          width: 192px;
          height: 56px;
          border-radius: 40px;
          background: #1C1C1C;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
      .serviceFirstCard-right{
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 100px;
        .serviceFirstCard-img{
          width: 260px;
          height: 260px;
          svg{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .serviceCard, .serviceBlackCard{
      width: calc(100% / 2 - 16px);
      margin-top: 32px;
      padding: 56px 32px;
      border-radius: 20px;
      background: #111;
      .serviceCard-icon{
        width: 80px;
        height: 80px;
        svg{
          width: 100%;
          height: 100%;
        }
      }
      .serviceCard-title{
        color: #FFF;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        margin-top: 40px;
      }
      .serviceCard-description{
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
      }
      .serviceCard-more{
        width: 192px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        border-radius: 40px;
        background: #1C1C1C;
        margin-top: 32px;
      }
    }
    .serviceCard{
      border-radius: 20px;
      background: #5056FD;
      .serviceCard-more{
      background: #676CFD;
    }
    }
  }

  @media screen and (max-width: 1250px){
    .serviceBlocks{
      width: 1020px;
    }
  }
  @media screen and (max-width: 1050px){
    overflow: hidden;
    padding: 52px $mainPadding 80px $mainPadding;
    position: relative;
    .serviceBlock-responsiveImg{
      width: unset;
      height: unset;
      opacity: 1;
      position: absolute;
      top: 80px;
      right: -50px;
      z-index: 100;
    }
    .serviceBlock-header{
      z-index: 101;
      width: 100%;
      align-items: unset;
      justify-content: unset;
      .serviceBlock-header-title{
        color: #FFF;
        text-align: left;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
      }
    }
    .serviceBlocks{
      width: 100%;
      margin-top: 40px;
      .serviceFirstCard{
        flex-direction: column;
        padding: 44px 12px;
        .serviceFirstCard-topImg{
          display: none;
        }
        .serviceFirstCard-left{
          width: 100%;
          .serviceFirstCard-more{
            width: 100%;
          }
        }
        .serviceFirstCard-right{
          display: none;
        }
      }
      .serviceCard, .serviceBlackCard{
        width: 100%;
        .serviceCard-more{
          width: 100%;
        }
      }
    }
  }
}